import { useLayoutEffect } from 'react'

import { gsap } from 'gsap'
import SplitText from '../SplitText'

import './hero-section.css'

function HeroSection() {
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const landingPageTl = gsap.timeline({
        delay: 0.5,
        defaults: {
          duration: 0.5,
          delay: 0.2,
          ease: "ease-in"
        }
      })

      landingPageTl
        .from(".main-header .company-info", {
          opacity: 0,
        })
        .from([
          ".main-header .toggle-theme-btn--mobile",
          ".main-nav__toggle-btn",
          ".main-nav__nav-link",
          ".main-header .toggle-theme-btn--desktop",
        ], {
          opacity: 0,
          yPercent: -100,
          duration: 0.3,
          stagger: 0.1,
        }, "<")
        .from(".hero-section__graphic", {
          xPercent: 50,
          opacity: 0,
        })
        .from(".hero-section__heading h2 > span, .hero-section__heading p", {
          clipPath: "inset(0 0 100% 0)",
          stagger: 0.05,
        })
        .from(".hero-section__content > .action-btn-group", {
          opacity: 0,
          yPercent: -50,
          duration: 0.75,
          delay: 0,
          ease: "back.inOut(2)",
        }, "-=0.3")
    })

    return () => ctx.revert()
  }, [])
  
  return (
    <div className="hero-section">
      <div className="hero-section__content-section">
        <div className="hero-section__content">
          <div className="hero-section__heading">
            <h2>
              <SplitText 
                type="words"
              >DEMOCRATIZING QUALITY HEALTHCARE</SplitText>
            </h2>
            <p>Artificial intelligence powered solutions</p>
          </div>

          <div className="action-btn-group">
            <a href="https://moremi.ai" target="_blank" rel="noreferrer" className="action-btn">Try Moremi AI now</a>
            <a href="https://platform.minohealth.ai" target="_blank" rel="noreferrer" className="action-btn" data-btn-blue>Try our chest X-Ray AI too</a>
          </div>
        </div>
      </div>

      <div className="hero-section__img-section">
        <iframe
          className="hero-section__graphic"
          src="https://www.youtube.com/embed/wgsuaUM5Ar0"
          title="Moremi AI: Towards Artificial General Intelligence for Health and Biology"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  )
}

export default HeroSection
