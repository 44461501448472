// @ts-check
"use strict";

import React, { useMemo, useState } from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from "recharts";
import { SyntaxKind, walk } from "html5parser";
import { getAttribute } from "../../../../components/html-renderer";

import "./moremi-charts.css";

/**
  * @param {Object} props
  * @param {Record<string, any>[]} props.data
  * @param {string} props.xAxis
  * @param {string[]} props.yAxis
  * @param {[number, number]} [props.domain]
  */
export function CustomBarChart({ data, xAxis, yAxis, domain=[0, 100] }) {
  const formattedData = useMemo(() => {
    return data.map(row => Object.assign(
      {}, row,
      Object.fromEntries(yAxis.map(key => [key, Number(row[key])]))
    ))
  }, [data, yAxis])

  const estimatedMaxXAxisLabelSize = data.length > 2
    ? Math.max(...formattedData.map(row => row[xAxis].length)) * 10
    : 20

  const angle = data.length > 2 ? -90: undefined
  const textAnchor = data.length > 2 ? "end": "middle"

  const chartViewAspectRatio = 16/9
  const [chartWidth, setChartWidth] = useState(500)
  const minChartViewHeight = 275
  const chartHeight = Math.max(chartWidth/chartViewAspectRatio, minChartViewHeight) + estimatedMaxXAxisLabelSize
  const aspectRatio = chartWidth/chartHeight

  const colors = useMemo(() => shuffle(BAR_COLORS), [BAR_COLORS])

  return (
    <ResponsiveContainer
      className="moremi-chart-wrapper"
      width="100%"
      aspect={aspectRatio}
      onResize={(width, height) => {
        if(width === 0 || height === 0) return
        setChartWidth(width)
      }}
    >
      <BarChart
        width={chartWidth}
        height={chartHeight}
        data={formattedData}
        margin={{
          top: 20,
          right: 10,
          left: 10,
          bottom: estimatedMaxXAxisLabelSize,
        }}
      >
        <CartesianGrid
          stroke="var(--chart-grid-color)"
          strokeDasharray="3 3"
        />
        <XAxis 
          type="category"
          dataKey={xAxis}
          angle={angle}
          textAnchor={textAnchor}
          axisLine={{ stroke: "var(--chart-axis-color)" }}
          tickLine={{ stroke: "var(--chart-axis-color)" }}
          tick={{ fill: "var(--chart-axis-value-color)" }}
        />
        <YAxis 
          type="number"
          domain={domain}
          label={yAxis.length === 1 ?{
            value: yAxis,
            angle: -90,
            position: "insideBottomLeft",
          }: undefined}
          tickCount={10}
          axisLine={{ stroke: "var(--chart-axis-color)" }}
          tickLine={{ stroke: "var(--chart-axis-color)" }}
          tick={{ fill: "var(--chart-axis-value-color)" }}
        />
        <Tooltip
          cursor={{ fill: 'var(--chart-tooltip-cursor-color)' }}
          itemStyle={{ color: 'currentColor', opacity: 0.75, fontWeight: 'bold' }}
          contentStyle={{
            background: 'var(--chart-tooltip-background-color)',
            borderRadius: 'var(--chart-tooltip-border-radius)',
            borderColor: 'var(--chart-tooltip-border-color)',
            color: "currentColor",
          }}
        />
        {yAxis.length > 1 && (
          <Legend verticalAlign="top" />
        )}
        {/* }<Bar key={index} dataKey={key} fill={color[index]} maxBarSize={30} /> */}
        {yAxis.map((key, barIndex) => (
          <Bar dataKey={key} maxBarSize={30} key={`bar-${barIndex}`} fill={colors[barIndex]}>
            {formattedData.map((entry, cellIndex) => (
              <Cell
                key={`cell-${cellIndex}`}
                fill={colors[(yAxis.length === 1 ? cellIndex: barIndex) % BAR_COLORS.length]}
              />
            ))}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

const BAR_COLORS = [
  "#ff6f61", "#ffb74d", "#a78bfa",
  "#77bfa3", "#2c7a7b", "#50c6a7",
  "#3a5a98", "#5dadec", "#66c2ff",
  "#ff8ba0", "#ff7043", "#ffd166",
  "#14b8a6", "#ffc947", "#9b5de5",
  "#4c6ef5", "#2bc16d", "#ffa07a",
]

/** @param {import("html5parser").ITag} table */
export function getDataFromTable(table) {
  let headers = [];
  /** @type {Record<string, any>[]} */
  let data = [];
  let currentRow = null;
  let caption = null;

  /** @type {{columnIndex: number, value: string, span: number}[]}} */
  let spanningCells = [];

  walk([table], {
    enter(node, parent) {
      if (node.type === SyntaxKind.Tag && node.name === 'th') {
        if (node.body && node.body.length > 0) {
          const headerText = node.body[0].type === SyntaxKind.Text
            ? node.body[0].value.trim()
            : '';
          headers.push(headerText);
        }
      }
      
      if (node.type === SyntaxKind.Tag && node.name === 'tr') {
        currentRow = {};
      }
      
      if (node.type === SyntaxKind.Tag && node.name === 'td') {
        let columnIndex = Object.keys(currentRow).length;

        // Check if this cell is part of a spanning row
        while(spanningCells.some(item => item.columnIndex === columnIndex)) {
          // Use the corresponding header as the key, or fallback to index
          const key = headers[columnIndex] || `column${columnIndex}`;
          
          const spanningCell = spanningCells.find(item => item.columnIndex === columnIndex);
          if(spanningCell == null) continue;
          currentRow[key] = spanningCell.value;
          spanningCell.span--;
          if(spanningCell.span === 0) {
            spanningCells = spanningCells.filter(item => item.columnIndex !== columnIndex);
          }

          columnIndex++;
        }

        if (currentRow !== null && node.body && node.body.length > 0) {
          const cellText = node.body[0].type === SyntaxKind.Text
            ? node.body[0].value.trim()
            : '';
          
          // Use the corresponding header as the key, or fallback to index
          const key = headers[columnIndex] || `column${columnIndex}`;
          
          currentRow[key] = cellText;

          const rowspan = getAttribute(node, "rowspan", Number) ?? 1;
          if(rowspan > 1) {
            spanningCells.push({ columnIndex, value: cellText, span: rowspan - 1 });
          }
        }

        columnIndex++;
        // Check if this cell is part of a spanning row
        while(spanningCells.some(item => item.columnIndex === columnIndex)) {
          // Use the corresponding header as the key, or fallback to index
          const key = headers[columnIndex] || `column${columnIndex}`;
          
          const spanningCell = spanningCells.find(item => item.columnIndex === columnIndex);
          if(spanningCell == null) continue;
          currentRow[key] = spanningCell.value;
          spanningCell.span--;
          if(spanningCell.span === 0) {
            spanningCells = spanningCells.filter(item => item.columnIndex !== columnIndex);
          }

          columnIndex++;
        }
      }

      if (node.type === SyntaxKind.Tag && node.name === 'caption') {
        if (node.body && node.body.length > 0) {
          caption = node.body[0].type === SyntaxKind.Text
            ? node.body[0].value.trim()
            : '';
        }
      }
    },
    
    leave(node) {
      // When leaving a row, add it to our data array if it's not empty
      if (node.type === SyntaxKind.Tag && node.name === 'tr' && currentRow !== null) {
        if (Object.keys(currentRow).length > 0) {
          data.push(currentRow);
        }
        currentRow = null;
      }
    }
  });

  return { data, caption };
}

/**
  * @param {Object} props
  * @param {Record<string, string>[]} props.data
  * @param {string | null} props.caption
  */
export function TableFromData({ data, caption }) {
  const columnKeys = Object.keys(data[0]);
  const rowspans = calculateRowspans(data);

  return (
    <div className="table-wrapper">
      <table>
        {caption != null && (
          <caption>{caption}</caption>
        )}

        <thead>
          <tr>
            {columnKeys.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnKeys.map((col, colIndex) => {
                if(rowspans[col][rowIndex] > 0) {
                  return (
                    <td 
                      key={colIndex}
                      rowSpan={rowspans[col][rowIndex]}
                    >
                      {row[col]}
                    </td>
                  )
                }
                return null
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

/** @param {Record<string, any>[]} data */
function calculateRowspans(data) {
  const columns = Object.keys(data[0]);
  const rowspans = Object.fromEntries(
    columns.map(col => [col, Array(data.length).fill(1)])
  )
  
  // Calculate rowspans by looking ahead
  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    for (let col of columns) {
      if (rowspans[col][rowIndex] === 1) { // Only check cells not already part of a rowspan
        let span = 1;
        // Look ahead at subsequent rows
        for (let nextRow = rowIndex + 1; nextRow < data.length; nextRow++) {
          if (data[nextRow][col] === data[rowIndex][col]) {
            span++;
            rowspans[col][nextRow] = 0; // Mark cell as part of a rowspan
          } else {
            break;
          }
        }
        rowspans[col][rowIndex] = span;
      }
    }
  }
  
  return rowspans;
};

/** 
  * @param {any[]} array
  * @see https://stackoverflow.com/a/2450976
  */
function shuffle(array) {
  let newArray = [...array];
  let currentIndex = array.length;

  while (currentIndex != 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex], newArray[currentIndex]];
  }

  return newArray;
}
